import * as React from 'react'
import styled from 'styled-components'

import { InfoAndImage } from 'views/components'
import { breakpoints, laptop, tablet, mobile } from 'shared/media-queries'
import { cyan } from 'shared/colors'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { SWProps } from 'shared/interfaces'

const getWordingWidth = screenWidth => {
	if (screenWidth >= breakpoints.desktop) {
		return '502px'
	} else if (screenWidth > breakpoints.tablet) {
		return '480px'
	}
	return '636px'
}

const Subtitle = styled.div`
	font-size: 38px;
	${laptop} {
		max-width: 380px;
	}
	${tablet} {
		max-width: 642px;
	}
	${mobile} {
		font-size: 32px;
		max-width: 100%;
		margin: 0 auto;
	}
`

const Info = styled.div`
	font-size: 22px;
	${laptop} {
		max-width: 480px;
	}
	${tablet} {
		max-width: 632px;
	}
	${mobile} {
		font-size: 21px;
		max-width: 100%;
		margin: 0 auto;
	}
`

const ImageContainer = styled.div`
	margin: 0 auto;
	${tablet} {
		max-width: 490px;
	}
	${mobile} {
		max-width: 100%;
	}
`

const SaveTimeImage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				fileName: file(
					relativePath: { eq: "bid-board/takeoffs/save-time/img/save-time.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1400) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	)

	return (
		<ImageContainer>
			<GatsbyImage
				fluid={data.fileName.childImageSharp.fluid}
				alt="Save Time Image"
			/>
		</ImageContainer>
	)
}

const BulletItem = styled.span`
	font-weight: 600;
`
const SaveTime: React.FC<SWProps> = ({ screenWidth }) => (
	<InfoAndImage
		screenWidth={screenWidth}
		image={<SaveTimeImage />}
		spacerWidths={['50px', '20px']}
		title="SAVE TIME"
		dotColor={cyan}
		subtitle={<Subtitle>Quickly turn a takeoff into an estimate.</Subtitle>}
		info={
			<Info>
				Eliminate manual entry and spend more time where it matters—bidding on
				jobs.
			</Info>
		}
		bulletItems={[
			<BulletItem>
				Organize takeoff items so you can quickly access everything during
				estimating
			</BulletItem>,
			<BulletItem>
				Break out quantities in detail to accurately price items
			</BulletItem>,
			<BulletItem>Understand the scope of a project at a glance</BulletItem>,
			<BulletItem>Export your takeoff list to CSV</BulletItem>,
		]}
		centered={screenWidth < breakpoints.laptop}
		wordingWidth={getWordingWidth(screenWidth)}
	/>
)

export default SaveTime
