import * as React from 'react'
import Layout from 'components/layout'
import {
	SectionSpacer,
	Footer,
	ContentWrapper,
	SectionBreak,
} from 'views/components'
import useScreenWidth from 'hooks/use-screen-width'

import Header from './header'
import { cyan } from 'shared/colors'
import MinimizeErrors from './minimize-errors'
import SaveTime from './save-time'
import EasyAccess from './easy-access'
import ResourceHubSection from './resource-hub'
import Checklist from './checklist'
import BBPFeaturette from './bid-board'

const TakeoffsLP = () => {
	const screenWidth = useScreenWidth()
	if (!screenWidth) {
		return null
	}

	return (
		<Layout
			title="Quantity Takeoffs for Bid Board Pro"
			description="The takeoff tool for Bid Board Pro helps you create accurate takeoffs, fast."
		>
			<Header screenWidth={screenWidth} />
			<ContentWrapper>
				<SectionSpacer />
				<Checklist />
				<SectionBreak />
				<MinimizeErrors screenWidth={screenWidth} />
				<SectionBreak />
				<SaveTime screenWidth={screenWidth} />
				<SectionBreak />
				<EasyAccess screenWidth={screenWidth} />
				<SectionBreak />
				<BBPFeaturette />
				<SectionBreak />
				<ResourceHubSection />
				<SectionSpacer />
			</ContentWrapper>
			<Footer
				wording="Create better bids in less time and win more work."
				demoButtonPadding="55px !important"
				subtext="Try Bid Board Pro today."
				ctaColor={cyan}
				showLearnMoreBBP
			/>
		</Layout>
	)
}

export default TakeoffsLP
