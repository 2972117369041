import * as React from 'react'
import { Img } from 'glamorous'

import { ResourceHub } from 'views/components'
import BlogImage1 from './img/blog-1.png'
import BlogImage12x from './img/blog-1@2x.png'
import BlogImage13x from './img/blog-1@3x.png'

import BlogImage2 from './img/blog-2.png'
import BlogImage22x from './img/blog-2@2x.png'
import BlogImage23x from './img/blog-2@3x.png'

import HowToImage from './img/how-to-image.png'
import HowToImage2x from './img/how-to-image@2x.png'
import HowToImage3x from './img/how-to-image@3x.png'

const cards = [
	{
		image: (
			<Img
				width="100%"
				src={BlogImage1}
				srcSet={`${BlogImage12x} 2x, ${BlogImage13x} 3x`}
			/>
		),
		title: 'Blog',
		wording: 'Why Subcontractors Should Do Takeoffs in the Cloud',
		cta: 'Read more',
		href: `https://constructionblog.autodesk.com/cloud-takeoffs/`,
	},
	{
		image: (
			<Img
				width="100%"
				src={BlogImage2}
				srcSet={`${BlogImage22x} 2x, ${BlogImage23x} 3x`}
			/>
		),
		title: 'Blog',
		wording: 'Introducing the New Takeoff Tool for Bid Board Pro',
		cta: 'Read more',
		href: `https://constructionblog.autodesk.com/takeoff-tool-bid-board-pro/`,
	},
	{
		image: (
			<Img
				width="100%"
				src={HowToImage}
				srcSet={`${HowToImage2x} 2x, ${HowToImage3x} 3x`}
			/>
		),
		title: 'How To',
		wording: 'How to create takeoffs in Bid Board Pro',
		cta: 'Read more',
		href: `https://buildingconnected.zendesk.com/hc/en-us/articles/360039489513-How-to-create-quantity-takeoffs-Bid-Board-Pro-`,
	},
]

const ResourceHubSection = () => (
	<ResourceHub
		title="The resource hub."
		subtitle="Get everything you need to know about takeoffs here."
		cards={cards}
	/>
)

export default ResourceHubSection
