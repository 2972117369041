import * as React from 'react'
import styled from 'styled-components'

import { InfoAndImage } from 'views/components'
import { breakpoints, laptop, tablet, mobile } from 'shared/media-queries'
import { cyan } from 'shared/colors'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { SWProps } from 'shared/interfaces'

const getWordingWidth = screenWidth => {
	if (screenWidth >= breakpoints.desktop) {
		return '502px'
	} else if (screenWidth > breakpoints.tablet) {
		return '480px'
	}
	return '636px'
}

const ImageWrapper = styled.div`
	width: 700px;
	${tablet} {
		width: 100%;
	}
`

const Subtitle = styled.div`
	font-size: 38px;
	${laptop} {
		max-width: 480px;
	}
	${tablet} {
		max-width: 642px;
	}
	${mobile} {
		font-size: 32px;
		max-width: 100%;
		margin: 0 auto;
	}
`

const Info = styled.div`
	font-size: 22px;
	${laptop} {
		max-width: 350px;
	}
	${tablet} {
		max-width: 550px;
	}
	${mobile} {
		font-size: 21px;
		max-width: 100%;
		margin: 0 auto;
	}
`

const AccessImage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				fileName: file(
					relativePath: { eq: "bid-board/takeoffs/easy-access/img/online.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1400) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	)

	return (
		<GatsbyImage
			fluid={data.fileName.childImageSharp.fluid}
			alt="Easy Access Image"
		/>
	)
}

const BulletItem = styled.span`
	font-weight: 600;
`
const EasyAccess: React.FC<SWProps> = ({ screenWidth }) => (
	<InfoAndImage
		screenWidth={screenWidth}
		image={<ImageWrapper>{<AccessImage />}</ImageWrapper>}
		spacerWidths={['50px', '20px']}
		title="EASY ACCESS"
		imageOnRight
		dotColor={cyan}
		subtitle={
			<Subtitle>
				View your takeoffs from anywhere, in one centralized place.
			</Subtitle>
		}
		info={<Info>Easily access and manage your takeoffs.</Info>}
		bulletItems={[
			<BulletItem>
				Access all files to past projects wherever you are
			</BulletItem>,
			<BulletItem>Make updates in real time as requirements change</BulletItem>,
			<BulletItem>Quickly find completed takeoffs</BulletItem>,
			<BulletItem>
				Access important data, like GC contact information, due dates, notes,
				and more
			</BulletItem>,
			<BulletItem>Assign ownership to relevant team members</BulletItem>,
		]}
		centered={screenWidth < breakpoints.laptop}
		wordingWidth={getWordingWidth(screenWidth)}
	/>
)

export default EasyAccess
