import * as React from 'react'
import styled from 'styled-components'
import CheckIcon from 'imgs/icons/colored-checkmark.svg'
import { tablet, laptop, mobile } from 'shared/media-queries'
import { grayText, cyan } from 'shared/colors'

const Title = styled.div`
	font-size: 20px;
	font-weight: 600;
	line-height: 1.2;
	letter-spacing: 1.2px;
	color: ${cyan};
	padding-bottom: 40px;
	${mobile} {
		width: 269px;
		font-size: 18px;
		margin: 0 auto;
	}
`
const ChecklistContainer = styled.div`
	display: flex;
	margin: 0 auto;
	justify-content: center;
	${mobile} {
		flex-direction: column;
		width: 215px;
	}
`
const Container = styled.div`
	text-align: center;
`

const ItemsContainer = styled.div`
	text-align: left;
	height: 82px;
	display: flex;

	flex-wrap: wrap;
	${tablet} {
		height: 98px;
	}
	${mobile} {
		align-items: center;
		height: auto;
	}
`

const ItemBlock = styled.div`
	display: flex;
	flex-direction: column;
`

const ItemRow = styled.div`
	display: flex;
	margin-right: 42px;
	margin-bottom: 15px;
	${tablet} {
		margin-right: 22px;
		margin-bottom: 15px;
		width: 135px;
	}
	${mobile} {
		width: auto;
	}
`

const ItemText = styled.div`
	font-size: 18px;
	font-weight: 600;
	line-height: 1.33;
	color: ${grayText};
	padding-left: 10px;
	width: 157px;
	${laptop} {
		width: 135px;
	}
	${mobile} {
		width: 215px;
	}
`

const Item: React.FC<{ title: string }> = ({ title }) => (
	<ItemRow>
		<CheckIcon height="20px" width="20px" /> <ItemText>{title}</ItemText>
	</ItemRow>
)

const Checklist = () => {
	const items = [
		<ItemBlock>
			<Item title="Equipment" />
			<Item title="Wood and plastics" />
		</ItemBlock>,
		<ItemBlock>
			<Item title="Finishes" />
			<Item title="Furnishings" />
		</ItemBlock>,
		<ItemBlock>
			<Item title="Specialties" />
			<Item title="Doors and windows" />
		</ItemBlock>,
		<ItemBlock>
			<Item title="Thermal and moisture protection" />
		</ItemBlock>,
	]

	return (
		<Container>
			<Title>BID BOARD PRO'S TAKEOFF TOOL IS BEST FOR: </Title>
			<ChecklistContainer>
				<ItemsContainer>{items}</ItemsContainer>
			</ChecklistContainer>
		</Container>
	)
}

export default Checklist
