import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

import { breakpoints } from 'shared/media-queries'

const BackgroundImage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				desktop: file(
					relativePath: { eq: "bid-board/takeoffs/header/img/desktop.png" }
				) {
					childImageSharp {
						fixed(height: 600) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				laptop: file(
					relativePath: { eq: "bid-board/takeoffs/header/img/laptop.png" }
				) {
					childImageSharp {
						fixed(height: 500) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				tablet: file(
					relativePath: { eq: "bid-board/takeoffs/header/img/tablet.png" }
				) {
					childImageSharp {
						fixed(height: 400) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				mobile: file(
					relativePath: { eq: "bid-board/takeoffs/header/img/mobile.png" }
				) {
					childImageSharp {
						fixed(height: 409) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
			}
		`
	)

	const sources = [
		{
			...data.desktop.childImageSharp.fixed,
			media: `(min-width: ${breakpoints.laptop}px)`,
		},
		{
			...data.laptop.childImageSharp.fixed,
			media: `(min-width: ${breakpoints.tablet}px)`,
		},
		{
			...data.tablet.childImageSharp.fixed,
			media: `(min-width: ${breakpoints.mobile})`,
		},
		data.mobile.childImageSharp.fixed,
	]

	return (
		<GatsbyImage
			fixed={sources}
			alt="header image"
			style={{ width: '100%', height: '100%' }}
		/>
	)
}

export default BackgroundImage
