import * as React from 'react'
import styled from 'styled-components'
import { Hero } from 'views/components'
import H1 from 'components/h1'
import { SWProps } from 'shared/interfaces'
import { breakpoints, mobile } from 'shared/media-queries'
import BackgroundImage from './background-image'
import { cyan } from 'shared/colors'

const getWordingWidth = screenWidth => {
	if (screenWidth >= breakpoints.laptop) {
		return '630px'
	} else if (screenWidth >= breakpoints.tablet) {
		return '700px'
	}
	return '480px'
}

const Heading = styled(H1)`
	font-size: 54px;
	${mobile} {
		font-size: 32px;
	}
`

const SubHeading = styled.div`
	font-size: 24px;
	${mobile} {
		font-size: 18px;
	}
`

const Header: React.FC<SWProps> = ({ screenWidth }) => (
	<Hero
		backgroundImage={<BackgroundImage />}
		heading={
			<Heading>Bid smart with better takeoffs in Bid Board Pro.</Heading>
		}
		subHeading={
			<SubHeading>
				Win the right work by making quick, accurate bids in your online bid
				board.
			</SubHeading>
		}
		ctaColor={cyan}
		wordingWidth={getWordingWidth(screenWidth)}
	/>
)

export default Header
