import * as React from 'react'
import { lightBlue, navy } from 'shared/colors'
import { mobile } from 'shared/media-queries'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import videoWebm from './img/BBPro-QTO_V2_@2x.webm'
import videomp4 from './img/BBPro-QTO_V3.mp4'

const Container = styled.div`
	text-align: center;
	${mobile} {
		height: auto;
	}
`

const Title = styled.div`
	font-size: 38px;
	font-weight: bold;
	text-align: center;
	color: ${navy};
	${mobile} {
		font-size: 36px;
	}
`

const CTA = styled.div`
	font-size: 24px;
	font-weight: bold;
	color: ${lightBlue};
	padding-top: 10px;
	padding-bottom: 35px;
`

const VideoContainer = styled.div`
	overflow: hidden;
	${mobile} {
		display: none;
	}
`

const ImageContainer = styled.div`
	display: none;
	${mobile} {
		display: inherit;
		height: 425px;
		width: 350px;
		margin: 0 auto;
	}
`
const Video = () => (
	<VideoContainer>
		<video height="100%" width="100%" autoPlay muted playsInline loop>
			<source src={videoWebm} type="video/webm" />
			<source src={videomp4} type="video/mp4" />
		</video>
	</VideoContainer>
)

const Image = () => {
	const data = useStaticQuery(
		graphql`
			query {
				fileName: file(
					relativePath: {
						eq: "bid-board/takeoffs/bid-board/img/bbp-mobile.png"
					}
				) {
					childImageSharp {
						fluid(maxHeight: 850) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	)

	return (
		<ImageContainer>
			<GatsbyImage
				fluid={data.fileName.childImageSharp.fluid}
				alt="bbp image"
				style={{ width: '100%', height: '100%' }}
			/>
		</ImageContainer>
	)
}

const BBPFeaturette = () => (
	<Container>
		<Title>
			Receive invites, manage bids, and do takeoffs—all from Bid Board Pro
		</Title>
		<a href={`/bid-board/`} target="_blank">
			<CTA>Learn more »</CTA>
		</a>
		<Video />
		<Image />
	</Container>
)

export default BBPFeaturette
