import * as React from 'react'
import styled from 'styled-components'

import { InfoAndImage } from 'views/components'
import { breakpoints, laptop, tablet, mobile } from 'shared/media-queries'
import { cyan } from 'shared/colors'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { SWProps } from 'shared/interfaces'

const getWordingWidth = screenWidth => {
	if (screenWidth >= breakpoints.desktop) {
		return '502px'
	} else if (screenWidth > breakpoints.tablet) {
		return '430px'
	}
	return '636px'
}

const Subtitle = styled.div`
	font-size: 38px;
	${laptop} {
		max-width: 380px;
	}
	${tablet} {
		max-width: 642px;
	}
	${mobile} {
		font-size: 32px;
		max-width: 100%;
		margin: 0 auto;
	}
`

const Info = styled.div`
	font-size: 22px;
	${laptop} {
		font-size: 20px;
		max-width: 480px;
	}
	${tablet} {
		max-width: 632px;
	}
	${mobile} {
		font-size: 21px;
		max-width: 100%;
		margin: 0 auto;
	}
`

const ImageContainer = styled.div`
	margin: 0 auto;
	${tablet} {
		max-width: 490px;
	}
	${mobile} {
		max-width: 100%;
	}
`

const ToolsImage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				fileName: file(
					relativePath: {
						eq: "bid-board/takeoffs/minimize-errors/img/tools.png"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1400) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	)

	return (
		<ImageContainer>
			<GatsbyImage
				fluid={data.fileName.childImageSharp.fluid}
				alt="Tools Image"
			/>
		</ImageContainer>
	)
}

const BulletItem = styled.span`
	font-weight: 600;
`
const MinimizeErrors: React.FC<SWProps> = ({ screenWidth }) => (
	<InfoAndImage
		screenWidth={screenWidth}
		image={<ToolsImage />}
		spacerWidths={['50px', '10px']}
		title="MINIMIZE ERRORS"
		imageOnRight
		dotColor={cyan}
		subtitle={
			<Subtitle>Go digital to easily create accurate takeoffs. </Subtitle>
		}
		info={
			<Info>
				Access the files and tools you need right in Bid Board Pro to generate
				error-free takeoffs. No more pencil and paper, and no more blurry
				print-outs.
			</Info>
		}
		bulletItems={[
			<BulletItem>Measure distance, area, and angle</BulletItem>,
			<BulletItem>Draw for area, linear, and count</BulletItem>,
			<BulletItem>Set scale</BulletItem>,
		]}
		centered={screenWidth < breakpoints.laptop}
		wordingWidth={getWordingWidth(screenWidth)}
	/>
)

export default MinimizeErrors
